@use "./variables" as *;

:root {
  $crsl-bg: transparent;
  $box-bg: #1e272e;
  $box-shadow: #0000001c;
  $box-shadow-border: #0000000f;
  $box-border: #fff;
}
@font-face {
  font-family: "EdoSZ";
  src: local("EdoSZ"), url(../assets/fonts/edosz.ttf) format("ttf");
}
@font-face {
  font-family: "OpenSansLight";
  src: local("OpenSansLight"), url(../assets/fonts/OpenSans-Light.ttf) format("ttf");
}
html,
body,
#root {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background: rgb(247, 253, 257);
  background: -moz-radial-gradient(circle, rgba(247, 253, 257, 1) 0%, rgba(230, 239, 426, 1) 100%);
  background: -webkit-radial-gradient(circle, rgba(472, 253, 257, 1) 0%, rgba(230, 239, 246, 1) 100%);
  background: radial-gradient(circle, rgba(247, 253, 257, 1) 0%, rgba(230, 239, 246, 1) 100%);

  font-family: "OpenSansLight", Arial, Helvetica, sans-serif;
  font-size: 16px;
  h1 {
    text-align: center;
    font-size: 4em;
    font-weight: bolder;
  }
  h2 {
    text-align: center;
    font-weight: bolder;
  }

  p {
    font-size: 1.25rem;
    text-align: center;
  }
}
