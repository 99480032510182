@use "./variables" as *;
@font-face {
  font-family: "EdoSZ";
  src: url(../assets/fonts/edosz.ttf) format("ttf");
  font-weight: normal;
  font-style: normal;
}
.information {
  &#info {
    margin-top: 120px;
  }
  div.nutzedeinestimme {
    span {
      display: inline;
      font-size: 1.5em;
      &.hashtag {
        font-family: "EdoSZ";
      }
    }
  }
  &#infomaterialien {
    //background-color: $primary;
    .cardlink {
      text-decoration: none;
      height: 100%;
    }
    .col-md-3 {
      background-color: transparent;
      border: none;
      .card-body {
        height: 100%;
        background-color: $quartiary;
        transition: opacity 0.5s ease;
        color: $quartiary-font;
        border-radius: 5px;
        &:hover {
          opacity: 0.8;
        }
        .card-title {
          font-weight: bolder;
          min-height: 2em;
        }
        .card-text {
          text-align: left;
          padding: 0 0 25px 0;
        }
        .card-link {
          position: absolute;
          bottom: 16px;
          margin-top: 16px;
          line-height: 1.5rem;
        }
      }
    }
  }
  padding: 3rem 0 1rem 0;
  .logo-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img.logo {
      max-width: 100vw;
      width: 600px;
    }
  }
}
