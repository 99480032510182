@use "./variables" as *;

nav:not(.yarl__thumbnails_track) {
  background-color: $primary;
  color: $primary-font !important;
  transition: padding 0.3s;
  &#home {
    position: fixed !important;
    position: -webkit-sticky;
    top: -1px !important;
    float: left !important;
    width: 100vw !important;
  }
  p,
  a {
    margin: 0;
    padding: 0.5em;
    color: $primary-font !important;
  }
  img {
    height: 50px;
    margin-right: 15px;
    //background-color: white;
    transition: height 0.3s;
  }

  &.large-navbar {
    img {
      height: 90px;
    }
  }

  &.small-navbar {
    img {
      height: 50px;
    }
  }
}
