@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";

$primary: rgb(20, 64, 150);

$primary-font: #fff;
$secondary: rgb(254, 237, 0);
$secondary-font: #000;
$tertiary: #f89f25;
$tertiary-font: #000;
$quartiary: #86c0e5;
$quartiary-font: #000;

$--bs-emphasis-color: #fff;
$--bs-emphasis-color-rgb: 255, 255, 255;
