@use "./variables" as *;
.kontaktdaten {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  > * {
    flex: 0.5 0.5 0px;
    a {
      text-decoration: none;
    }
  }
}
