@use "./variables" as *;

#ausstellung {
  width: 100%;
  .row {
    width: 100%;
    .custom-col {
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      margin: 10px;
      width: calc(100% / 9);
      height: unset;
      img {
        box-shadow: 2px 2px 3px #333;
        transition: all 0.3s ease-in-out;
        width: 100%;
        &:hover {
          transform: scale(1.1); /* Increase width on hover */
        }
      }
    }
  }
  .btn {
    background-color: $primary;
    transition: opacity 0.5s ease;
    &:hover {
      opacity: 0.9;
    }
  }
}
.yarl__navigation_next,
.yarl__navigation_prev {
  .yarl__icon {
    height: 64px !important;
    width: 64px !important;
  }
  &:disabled {
    display: none;
  }
}

.yarl__slide_wrapper {
}
